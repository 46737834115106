import * as types from "../constants/authConstant";
import authApi from '@/api/authApi';
import cookie from '@/helpers/cookie';

export const state = {
    user: null,
    isLogined: false,
};

export const getters = {
    user: state => state.user,
    isLogined: state => state.isLogined,
    ssoToken: state => state.ssoToken
};

export const mutations = {
    [types.AUTHENTICATE](state, res){
        state.user = res.user;
        state.isLogined = true;
    },
    [types.SET_PROPERTIES](state, res) {
        state.user = {
            ...state.user,
            ...res
        };
    }
};

export const actions = {
    async authenticate({commit}, params) {
        try {
            const { data } = await authApi.authenticate({
                SSOToken: localStorage.getItem('employer_sso_token')
            });
            commit(types.AUTHENTICATE, data);
            return data;
        } catch (e) {
            if (e.response && e.response.status == 401) {
                commit(types.AUTHENTICATE, {
                    user: null,
                    isLogined: false
                });
            }
        }
    },
    setProperties({commit}, params) {
        commit(types.SET_PROPERTIES, params);
    }
};

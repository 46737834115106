import cookie from "@/helpers/cookie";

export default async function guest({next, to, from, store}) {
    if (store.getters['auth/isLogined']) {
        return next({
            name: 'app.conversations'
        });
    }
    if (to.query.refresh) {
        localStorage.removeItem('employer_sso_token');
        return next();
    }
    if (localStorage.getItem('employer_sso_token')) {
        await store.dispatch('auth/authenticate');
        if(store.getters['auth/isLogined']) {
            return next({
                name: 'app.conversations'
            });
        }
        localStorage.removeItem('employer_sso_token');
    }
    return next();
}
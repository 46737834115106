import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import SocketConfig from '@/config/socket';
import cookie from '@/helpers/cookie';

const socket = io(process.env.VUE_APP_SOCKET_SERVER);

if (localStorage.getItem('employer_sso_token')) {
    socket.on('connect', () => {
        const tab_id = Math.random().toString(36).substring(7);
        sessionStorage.setItem('topcv_connect_tab_id', tab_id);
        localStorage.setItem('topcv_connect_last_tab_id', tab_id);
        const lastTabs = JSON.parse(localStorage.getItem('topcv_connect_last_tabs')) || [];
        lastTabs.push(tab_id);
        localStorage.setItem('topcv_connect_last_tabs', JSON.stringify(lastTabs));
        socket.emit('authenticate', {
            employer_sso_token: localStorage.getItem('employer_sso_token'),
            ac: SocketConfig.roles.EMPLOYER,
            tab_id
        });
    });
}

Vue.use(VueSocketIOExt, socket);


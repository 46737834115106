<template>
    <div v-if="show" class="position-relative">
        <div class="progress" :style="{
            width: `${percent}%`,
            height: height,
            backgroundColor: canSuccess? color : failedColor,
            opacity: show ? 1 : 0}"
        />
        <div class="loading-root">
            <loading-circle :text="`Đang tải dữ liệu, vui lòng đợi trong giây lát...${percent}%`" />
        </div>
    </div>
</template>

<script>
import LoadingCircle from './LoadingCircle';
export default {
    data: () => ({
        percent: 0,
        show: false,
        canSuccess: true,
        duration: 3000,
        height: '3px',
        color: '#212f3f',
        failedColor: '#ff0000',
    }),
    components: {
        LoadingCircle
    },
    methods: {
        start () {
            this.show = true;
            this.canSuccess = true;
            if (this.timer) {
                clearInterval(this.timer);
                this.percent = 0;
            }
            this.cutter = 10000 / Math.floor(this.duration);
            this.timer = setInterval(() => {
                this.increase(this.cutter * Math.random());
                if (this.percent > 95) {
                    this.finish();
                }
            }, 100);
            return this;
        },
        set (num) {
            this.show = true;
            this.canSuccess = true;
            this.percent = Math.floor(num);
            return this;
        },
        get () {
            return Math.floor(this.percent);
        },
        increase (num) {
            this.percent = this.percent + Math.floor(num);
            return this;
        },
        decrease (num) {
            this.percent = this.percent - Math.floor(num);
            return this;
        },
        finish () {
            this.percent = 100;
            this.hide();
            return this;
        },
        pause () {
            clearInterval(this.timer);
            return this;
        },
        hide () {
            clearInterval(this.timer);
            this.timer = null;
            this.show = false;
            this.$nextTick(() => {
                setTimeout(() => {
                    this.percent = 0;
                }, 200);
            });
            return this;
        },
        fail () {
            this.canSuccess = false;
            return this;
        }
    }
};
</script>
<style scoped>
    .progress {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 2px;
        width: 0%;
        transition: width 0.2s, opacity 0.4s;
        opacity: 1;
        z-index: 999999;
    }
    :root{
        --loader-width: 70px;
        --loader-height: 70px;
        --loader-color-primary: #212f3f;
        --loader-color-secondary: #eee;
        --line-width: 3px;
        --animation-duration: 2s;
        --loader-initial-scale: 0.1;
    }
    .loading-root {
        position: fixed;
        height: 100%;
        width: 100%;
        background: #fff;
        z-index: 99999;
        top: 0;
    }
</style>
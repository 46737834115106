import Vue from 'vue';
import VTooltip from 'v-tooltip';
import App from './App.vue';
import router from './router';
import store from '@/store';
import '@/layouts';
import '@/plugins';
import 'bootstrap'

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

token && localStorage.setItem('employer_sso_token', token);

Vue.config.productionTip = false;
Vue.use(VTooltip);

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')

export default {
    actions: {
        SEND_MESSAGE: 'SEND_MESSAGE',
        RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
        MARK_AS_SEEN: 'MARK_AS_SEEN',
        ONLINE_STATUS: 'ONLINE_STATUS',
        NOTIFY_WHEN_AWAY: 'NOTIFY_WHEN_AWAY'
    },
    roles: {
        USER: 'U',
        EMPLOYER: 'E'
    }
}
import auth from '@/middlewares/auth';
import guest from '@/middlewares/guest';

const page = path => () => import(`@/pages/${path}`).then(m => m.default || m);

export default [
    {
        path: '/',
        name: 'app.home',
        meta: {
            middleware: [guest],
            layout: 'DefaultLayout',
        },
        component: page('Home')
    },
    {
        path: '/conversations',
        name: 'app.conversations',
        meta: {
            middleware: [auth],
            layout: 'DefaultLayout',
        },
        component: page('Conversation')
    },
    {
        path: '/conversations/new/:user_id',
        name: 'app.new-conversation',
        meta: {
            middleware: [auth],
            layout: 'DefaultLayout',
        },
        component: page('Conversation')
    }
];

import axios from "axios";
import cookie from "@/helpers/cookie";
import router from "@/router";

const baseApiURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(request => {
    request.baseURL = baseApiURL;
    request.headers.common['Authorization'] = "Bearer";
    // if (cookie.has('employer_sso_token')) {
    //     request.headers.common['SSOToken'] = cookie.get('employer_sso_token');
    // }
    let token = localStorage.getItem('employer_sso_token');
    if (token) {
        request.headers.common['SSOToken'] = token
    }

    return request;
});

axios.interceptors.response.use(response => response, error => {
    if (error.response) {
        let willBeReloadStatus = [401, 404, 500];
        if (error.response.status == 401) {
            // cookie.delete('employer_sso_token');
            localStorage.removeItem('employer_sso_token')
        }
        let currentUrl = document.URL;
        if (currentUrl[currentUrl.length - 1] == "/") {
            currentUrl = currentUrl.slice(0, -1);
        }
        if (willBeReloadStatus.includes(error.response.status) && currentUrl != location.origin) {
            return window.location.href = location.origin;
        }
    }
    return Promise.reject(error);
});

